import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Schutz vor Viren und Trojanern",
  "author": "Jochen Ritscher",
  "categories": ["faqs"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false,
  "tags": ["karriereseiten-api"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Als Dateianhang sind im Karriereportal-Standard lediglich PDF-Dateien bis zu einer Größe von 5MB erlaubt. Das System prüft nicht nur die Datei-Endung sondern auch den MIME-Type des Dokuments gemäß `}<a parentName="p" {...{
        "href": "http://www.iana.org/assignments/media-types/media-types.xhtml"
      }}>{`http://www.iana.org/assignments/media-types/media-types.xhtml`}</a>{` . Alle Dateianhänge werden serverseitig mit den aktuellen Definitionen von `}<a parentName="p" {...{
        "href": "https://www.clamav.net/"
      }}>{`https://www.clamav.net/`}</a>{` auf Viren geprüft und werden bei Verdacht direkt im Benutzerdialog abgelehnt. Eine Zweitprüfung der gelieferten Inhalte auf dem Fileshare wird zusätzlich empfohlen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      